<template>
  <div>
    <!-- 搜索框 -->
    <van-row type="flex" align="center" class="search">
      <van-col style="flex: 1">
        <van-search v-model="keywords" placeholder="产品名称/游戏类型" shape="round" />
      </van-col>
      <van-col>
        <van-button @click="fnSearch" type="primary">搜索</van-button>
      </van-col>
    </van-row>
    <!-- /搜索框 -->
    <!-- 搜索历史 -->
    <div class="search-box" v-if="!isSearch && searchHistory.length">
      <div class="search-box-top">
        <h2>搜索历史</h2>
        <span class="search-box-top-del" @click="fnSearchDel"></span>
      </div>
      <div class="search-box-list">
        <div class="search-box-list-item" v-for="(item, index) in searchHistory" :key="index">
          <span @click="fnClickSearch(item)">{{ item }}</span>
        </div>
      </div>
    </div>
    <!-- /搜索历史 -->
    <!-- 搜索列表 -->
    <div class="search-list" v-else-if="searchList.length">
      <ul>
        <li v-for="(item, index) in searchList" :key="index">
          <router-link
            class="a1"
            :to="{ path: '/details', query: { game_id: item.game_id, game_name: item.game_name } }"
          >
            <div class="search-list-left">
              <h2 v-html="$options.filters.keyword(item.game_name, keywords)" class="search-list-left-h2"></h2>
              <p>{{ game_type_name[item.game_type] }}</p>
            </div>
          </router-link>
          <div class="search-list-right">
            <span v-if="!item.concern" @click="fnClickGuanzhu(index)">关注</span>
            <span class="no" @click="fnClickUnGuanzhu(index)" v-else>已关注</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="search-list-no" v-else>
      <em></em>
      <p>暂无搜索内容</p>
    </div>
    <!-- /搜索列表 -->
  </div>
</template>

<script>
import { gameSearchTips, gameSearch, gameSearchHistoryClear } from '@/api/search'
import { fnGameFocus, fnGameUnfocus } from '@/common/common'
export default {
  data() {
    return {
      isSearch: false,
      keywords:'',
      //搜索历史列表
      searchHistory: [],
      //搜索列表
      searchList: [],
      game_type_name: {
        1: '网页游戏',
        2: '国内游戏',
        5: '海外游戏'
      }
    }
  },
  created() {
    this.fnInit()
  },
  methods: {
    fnInit() {
      this.fnGameSearchTips()
    },
    /**
     * 搜索历史
     */
    fnGameSearchTips() {
      gameSearchTips().then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          this.searchHistory = res.history
        }
      })
    },
    /**
     * 点击历史搜索
     */
    fnClickSearch(item) {
      this.keywords = item
      this.fnSearch()
    },
    /**
     * 搜索列表
     */
    fnSearch() {
      if (!this.keywords) {
        this.$toast('请输入搜索关键词')
        return
      }
      const params = {
        keywords: this.keywords //(string,normal) - 关键词
      }
      this.isSearch = true
      gameSearch(params).then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          const resList = res.list
          // for (let index = 0; index < resList.length; index++) {
          //   const element = resList[index]
          //   resList[index].game_name = element.game_name.replace(this.keywords, '<span>' + this.keywords + '</span>')
          // }
          this.searchList = resList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 清除历史
     */
    fnSearchDel() {
      gameSearchHistoryClear().then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          this.searchHistory = []
          this.$toast('清除完成')
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 关注
     */
    fnClickGuanzhu(index) {
      const params = {
        game_id: this.searchList[index].game_id
      }
      fnGameFocus(
        params,
        () => {
          this.searchList[index].concern = 1
        },
        (msg) => {
          this.$toast.fail(msg)
        }
      )
    },
    /**
     * 取消关注
     */
    fnClickUnGuanzhu(index) {
      const params = {
        game_id: this.searchList[index].game_id
      }
      fnGameUnfocus(
        params,
        () => {
          this.searchList[index].concern = 0
        },
        (msg) => {
          this.$toast.fail(msg)
        }
      )
    }
  },
  filters: {
    keyword(value, key) {
      console.log(value,)
      if (!value) return ''
      const a = value.replace(key, '<span>' + key + '</span>')
      console.log(a)
      return a
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  padding: 0 (32 / @num);
  background-color: #fff;

  .van-col {
    font-size: 0;
  }
  .van-search {
    padding: (32 / @num) (24 / @num) (32 / @num) 0;
    .van-field {
      padding-left: (20 / @num) !important;
    }
    .van-search__content--round {
      background: #fff;
      border: (2 / @num) solid @paramcolor;
    }
  }

  .van-button--primary {
    height: (68 / @num);
    line-height: (66 / @num);
    padding: 0 (46 / @num);
    background-color: @paramcolor;
    border-color: @paramcolor;
    border-radius: (44 / @num);
  }
}
.search-list {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      margin-bottom: (4 / @num);
      padding: 0 (32 / @num);
      height: (128 / @num);
      a {
        flex: 1;
      }
      .search-list-left {
        flex: 1;
        h2 {
          font-size: @font-30;
          color: @font-2f2f2f;
          margin-bottom: (10 / @num);
          font-weight: normal;
          span {
            color: @paramcolor;
          }
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          color: @paramcolor;
          font-size: @font-24;
          width: (130 / @num);
          height: (40 / @num);
          background: linear-gradient(180deg, #e5f0ff 0%, #f7faff 100%);
          border-radius: (8 / @num);
        }
      }
      .search-list-right {
        width: (128 / @num);
        height: (48 / @num);
        span {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: @font-24;
          color: @paramcolor;
          border-radius: (28 / @num);
          border: (2 / @num) solid @paramcolor;
          &.no {
            color: @font-a3a3a3;
            background: @font-f8f8f8;
            border-color: @font-f8f8f8;
          }
        }
      }
    }
  }
}
.search-box {
  background: #fff;
  .search-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: (12 / @num) (32 / @num) (20 / @num);
    h2 {
      font-size: @font-24;
      color: @font-a3a3a3;
      font-weight: normal;
    }
  }
  .search-box-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 (32 / @num);
    .search-box-list-item {
      margin-right: (16 / @num);
      margin-bottom: (16 / @num);
      span {
        padding: 0 (20 / @num);
        height: (64 / @num);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: @font-24;
        color: @paramcolor;
        border-radius: (36 / @num);
        color: @font-a3a3a3;
        background: @font-f8f8f8;
      }
    }
  }
  .search-box-top-del {
    width: (26 / @num);
    height: (28 / @num);
    background: url('../../assets/shanchu@2x.png') no-repeat;
    background-size: 100%;
  }
}
.search-list-no {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: (40 / @num);
  width: 100%;
  em {
    display: block;
    margin-left: (30 / @num);
    width: (166 / @num);
    height: (150 / @num);
    background: url('../../assets/nolist@2x.png') no-repeat;
    background-size: 100%;
  }
  p {
    padding-top: (30 / @num);
    color: @font-a3a3a3;
    font-size: @font-24;
  }
}
</style>

<style lang="less">
.search-list-left-h2 {
  span {
    color: @paramcolor;
  }
}
</style>