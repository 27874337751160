import request from '@/utils/request'

const api = {
  gameSearch: '/api/game/search',//搜索
  gameSearchTips: '/api/game/searchtips', //搜索建议
  gameSearchHistoryClear: '/api/game/searchhistoryclear'//清除搜索历史
}


export function gameSearch(parameter) {
  return request({
    url: api.gameSearch,
    method: 'get',
    params: parameter
  })
}

export function gameSearchTips(parameter) {
  return request({
    url: api.gameSearchTips,
    method: 'get',
    params: parameter
  })
}

export function gameSearchHistoryClear(parameter) {
  return request({
    url: api.gameSearchHistoryClear,
    method: 'get',
    params: parameter
  })
}