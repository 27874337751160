import { gameFocus, gameUnfocus } from '@/api/follow'
import { Toast } from 'vant'

/**
 * 关注
 * @param {*} options // 参数
 * @param {*} sucCallback // 成功回调
 * @param {*} errCallback // 失败回调
 */
export function fnGameFocus(options, sucCallback, errCallback) {
  gameFocus(options).then((data) => {
    const error = data.error
    if (error === 1000) {
      sucCallback(data.res)
      Toast('关注成功')
    } else {
      errCallback(data.msg)
    }
  })
}

/**
 * 取消关注
 * @param {*} options // 参数
 * @param {*} sucCallback // 成功回调
 * @param {*} errCallback // 失败回调
 */
export function fnGameUnfocus(options, sucCallback, errCallback) {
  gameUnfocus(options).then((data) => {
    const error = data.error
    if (error === 1000) {
      sucCallback(data.res)
      Toast('已取消关注')
    } else {
      errCallback(data.msg)
    }
  })
}